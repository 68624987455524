<template>
  <div class="box-padding activity-bottom">
    <div class="activity-text">
      <h2>活动时间</h2>
      <p class="activity-bold">2018-5-1 00:00:01 至 2018-9-25 14:00:00</p>
    </div>
    <div class="activity-text">
      <h2>活动对象</h2>
      <p class="activity-bold">全国修理厂（每个手机号码视为一个参赛修理厂，多个手机号码的积分不做合并）</p>
    </div>
    <div class="activity-text">
      <h2>活动参与方式</h2>
      <p class="activity-bold">1、 下载安装“大大汽修”参与活动</p>
      <p class="activity-bold">2、 关注微信公众号：达欧汽车配件（活动地址在公众号底部菜单）参与活动</p>
      <p class="activity-bold">3、 登录PC官网 http://www.phc-dow.com 参与活动</p>
    </div>
    <div class="activity-text">
      <h2>积分获取方式</h2>
      <p class="activity-bold">1、 修理厂用户通过扫描达欧配件产品上的二维码（或发送短信987#防伪码#至12114），并完善账号信息（首次）即可获得对应的产品积分，配件扫码积分可登陆jf.phc-dow.com进行查询。</p>
      <p class="activity-bold">2、 参与“大大汽修”APP每日任务，每月任务。</p>
      <p>1） 首次注册成为“大大汽修”APP用户，可获得200积分。（注册用户定义为“修理厂”用户）</p>
      <p>2） 每月首次在“大大汽修”开工单可获得50积分。每月获得积分上限50积分</p>
      <p>3） 在“大大汽修”开工单，每日前4单，每单送50分。每日获得积分上限200分</p>
      <p>4） 每月邀请的第一位好友加入“大大汽修”成为注册用户可获得积分450分。每月获得积分上限450积分</p>
      <p>5） 每日分享“大大汽修”到微信、QQ等等可获得5积分。每日获得积分上限为5积分</p>
      <p> *本任务中的“每月”，“每日”指自然月及自然日。</p>
    </div>
    <div class="activity-text">
      <h2>积分的作用</h2>
      <h3>1、积分种类</h3>
      <p>1） 实际积分：代表产品本身的分值，修理厂用户扫码即可获得，该积分具有实际价值，可用于积分官网的礼品兑换，扫码积分任何情况下不做清零。</p>
      <p>2） 排名积分：仅用于本次活动的排名使用，不具有实际价值，不能用于积分官网的礼品兑换，活动结束后该积分即清零。</p>
      <P>本说明中除配件产品本身扫码获得的积分为实际积分外，活动中抽奖赠送的积分均为排名积分。</P>
      <h3>2、积分排行榜</h3>
      <p class="activity-bold">每期积分排行榜截止25日14:00，最终排名在17:00进行公布，积分排行榜按积分的多少，从高到低进行排序；积分相同的情况，按到达该积分的时间先后进行排序，先到达的排名在前。</p>
      <p>1） 赛期排行榜，周期：</p>
      <p>第一期：2018-5-1 至 2018-5-25（14:00）</p>
      <p>第二期：2018-5-25（接上期） 至 2018-6-25（14:00）</p>
      <p>第三期：2018-6-25（接上期） 至 2018-7-25（14:00）</p>
      <p>第四期：2018-7-25（接上期） 至 2018-8-25（14:00）</p>
      <p>第五期：2018-8-25（接上期） 至 2018-9-25（14:00）</p>
      <p>赛期排行榜积分计算规则</p>
      <p>赛期排名积分 = 扫码积分 + 任务积分 + 抽奖积分</p>
      <p>扫码积分：赛期内购买达欧配件产品，通过扫描防伪码获得的积分</p>
      <p>任务积分：赛期内“大大汽修”APP任务获得的积分</p>
      <p>抽奖积分：赛期内抽奖活动中获得的积分</p>
      <p>赛期排行榜的积分结算周期为上月25日14:00:01至当月25日14:00:00。（5月作为第一个赛期，排行积分从2018-5-1 00:00:01开始计算），排名积分将在当月25号归零。 当月归零的积分仍然统计在总排行榜中。</p>
      <p>举例：5月5000排名积分在25日14:00:01归零，5000分依然会累计在2016-11-15 17:00:01至2018-9-25 14:00:00的总排行中。</p>
      <p>每个赛期，排行第一的修理厂将获得我司提供的奖品（注：此奖项为赛期内排行第一名获得，不出现在抽奖环节中）</p>
      <p>2） 总排行榜，周期：2016-11-15 17:00:01至2018-9-25 14:00:00</p>
      <p>总排行榜积分计算规则</p>
      <p>总排名积分 = 累计扫码积分 + 累计任务积分 + 累计抽奖积分</p>
      <p>累计扫码积分：2016-11-15 17:00:01至2018-9-25 14:00:00购买达欧配件产品，通过扫描防伪码获得的积分 </p>
      <p>累计任务积分：2018-5-1 00:00:01至2018-9-25 14:00:00 “大大汽修”APP任务获得的积分</p>
      <p>累计抽奖积分：2018-5-1 00:00:01 至2018-9-25 14:00:00抽奖活动中获得的积分</p>
      <p>总排名积分第一的修理厂将获得我司提供的超级大奖（注：此奖项为总排行第一名获得，不出现在抽奖环节中）</p>
      <h3>3、 积分抽奖</h3>
      <p class="activity-bold">在赛期排行榜和总排行榜中，不同的积分数量对应不同的积分段位以及抽奖次数，修理厂可在当月1 - 25日（14:00）进行抽奖，当月25日14:00后未使用的抽奖次数清零。</p>
      <p class="activity-bold">（积分为购买产品扫描防伪码获得的积分，完成“大大汽修”任务和抽奖获得的积分的总和） </p>
      <div class="table-activity">
        <table>
          <tbody>
            <tr>
              <th>段位</th>
              <th>月度排名积分</th>
              <th>获得抽奖次数</th>
            </tr>
            <tr>
              <td>王者</td>
              <td>2000分及以上</td>
              <td>+4次</td>
            </tr>
            <tr>
              <td>钻石</td>
              <td>1500- 1999分</td>
              <td>+2次</td>
            </tr>
            <tr>
              <td>铂金</td>
              <td>1000 - 1499分</td>
              <td>+2次</td>
            </tr>
            <tr>
              <td>黄金</td>
              <td>500 - 999分</td>
              <td>+1次</td>
            </tr>
            <tr>
              <td>白银</td>
              <td>200 - 499分</td>
              <td>+1次</td>
            </tr>
            <tr>
              <td>青铜 </td>
              <td>0– 199分</td>
              <td>0次</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>每期最高获得10次抽奖机会</p>
      <h3>4、 赛区规则</h3>
      <p class="activity-bold">由于参与此次活动的修理厂数量众多，特此将本次活动按地区省市和商用车分为四个赛区</p>
      <p>1区：河南、安徽、山东、江苏、黑龙江、吉林、上海、北京、内蒙古</p>
      <p>2区：湖北、浙江、湖南、江西、福建、四川、重庆、广西、贵州</p>
      <p>3区：河北、山西、辽宁、天津、甘肃、青海、宁夏、新疆、西藏、陕西、云南、广东、海南</p>
      <h3>4区：全国范围的商用车</h3>
      <p class="activity-bold">赛区归属方式依据参与活动注册的手机号归属地来进行划分，活动开始后参赛人员赛区不能进行调整。</p>
      <p class="activity-bold">赛期排行榜、赛期抽奖、总排行榜都为独立分区排行，独立分区积分第一名获奖，并进行独立分区抽奖。</p>
      <h3>5、 活动奖项</h3>
      <p>1） 排名奖励 总排行榜积分第一名可在9月份获得我司提供超级大奖（各赛区总计5项奖品）
      </p>
      <p>赛期积分排行榜积分第一名可在5 - 8月获得我司提供的月度大奖（4个月每个月各区1项奖品，总计4项）</p>
      <p>2） 抽奖奖励（5 - 9月，各赛期各赛区获得）</p>
      <p>抽奖一等奖：2名（商用车区1名） </p>
      <p>抽奖二等奖：3名（商用车区2名）</p>
      <p>抽奖三等奖：30名（商用车区5名） </p>
      <p>抽奖普照奖：若干</p>
      <h3>6、 结果公布时间</h3>
      <p>1） 我司会在每月25日17:00:00公布最终积分排名结果</p>
      <p>2） 积分抽奖中奖者我司在活动页面中实时显示</p>
      <h3>7、 兑奖规则</h3>
      <p>1） 实物及话费奖品我司将在当期活动结束后7个工作日内进行兑现</p>
      <p>2） 积分奖励会在中奖后即时添加到用户对应的排名积分中</p>
      <h3>8、大奖积分说明</h3>
      <p>活动从5月1日起，至9月25日结束，共五期：</p>
      <p>1）修理厂在最后一期活动中，当期获得积分不能超过第二期至第四期获得累计积分的3倍，超出部分积分不能参与活动评比</p>
      <p>2）在最后一期最终大奖评比中，汽车大奖获得者必须满足第二期至第四期活动，每期积分均不低于2000分</p>
      <p style="margin-top:20px;">本活动最终解释权归上海达欧百希特汽车配件有限公司所有</p>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  mounted () {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }
}
</script>

<style lang="scss" scoped>
@import "~styles/index.scss";
.activity-text {
  font-size: px2rem(28px);
  margin: px2rem(60px) 0 0 0;
  & h2 {
    font-size: px2rem(32px);
    border-left: 4px solid $color-highlight;
    padding-left: px2rem(20px);
    margin: 0 0 px2rem(20px) 0;
    font-weight: bold;
    //  line-height: px2rem(55px);
  }
  & h3 {
    font-weight: bold;
    line-height: px2rem(40px);
    margin: px2rem(20px) 0;
  }
  & p {
    line-height: px2rem(50px);
  }
  & .table-activity {
    width: px2rem(690px);
    // border: 1px solid $color-line;
    line-height: px2rem(80px);
    margin: px2rem(20px) 0;
    & table {
      width: 100%;
      text-align: center;
      & th {
        height: px2rem(80px);
        background: #f0f0f0;
        line-height: px2rem(80px);
        border: 1px solid $color-line;
        width: 33.33%;
      }
      & td {
        line-height: px2rem(80px);
        border: 1px solid $color-line;
        width: 33.33%;
      }
    }
  }
  .activity-bold {
    font-weight: bold;
  }
}
.activity-bottom {
  margin-bottom: px2rem(103px);
}
</style>
